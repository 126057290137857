<section>
  <h2 *ngIf="!this.isKarrierePage" class="title">Kontakt</h2>
  <div nz-row class="contact">
    <div *ngIf="!this.isKarrierePage" nz-col nzXs="24" nzMd="12" class="contact__left">
      <div nz-row>
        <div class="contact__title">
          Sie möchten ein digitales Projekt umsetzen? Sprechen Sie uns
          unverbindlich an.
        </div>
        <p class="contact__description">
          Haben Sie Fragen zu unseren Angeboten oder wünschen eine persönliche
          Beratung? Senden Sie uns Ihre individuellen Anforderungen.<br /><br />
          Wir melden uns umgehend zurück!
        </p>
      </div>

      <h5 class="contact__person__greeting">
        Ich freue mich über Ihre Kontaktaufnahme:
      </h5>
      <div nz-row class="contact__person">
        <div nz-row class="contact__person__detail">
          <img nz-image nzSrc="../../../assets/pictures/maja.jpg" alt="Kontaktperson Maja Schrödter" class="contact__person__image" />
          <div class="contact__person__info">
            <div class="contact__person__info__name">
              <div>
                <strong>Maja Schrödter</strong><br />
                Kundenkommunikation & Betreuung<br />
                <span class="mail-span"><span nz-icon nzType="mail" nzTheme="outline"></span><a
                    href="mailto:info@digitalklang.de" class="mail">info&#64;digitalklang.de</a></span><br />
                    <div>
                      <span class="mobile-only-tel">
                          <span nz-icon nzType="phone" nzTheme="outline"></span>
                          <a href="tel:+4930616718230">+49 30 616 718 23 0</a>
                          <span class="text-only">+49 30 616 718 23 0</span>
                      </span>
                  </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h2 *ngIf="this.isKarrierePage" class="title__career">Deine Ansprechpartnerin</h2>
    <div *ngIf="this.isKarrierePage" nz-col nzXs="24" nzMd="12" class="contact__left__career">
      <div nz-row class="contact__person__career">
        <div nz-row class="contact__person__detail__career">
          <img nz-image nzSrc="../../../assets/pictures/carolin.png" alt="Kontaktperson Carolin Grandl" class="contact__person__image__career" />
          <div class="contact__person__info__career">
            <div class="contact__person__info__name">
              <div>
                <p class="contact-text">Gerne kannst Du mich auch anrufen.</p>
                <div class="contact-info">
                  <div class="fullname">Carolin Grandl</div>
                  <span class="mail-span"><span nz-icon nzType="mail" nzTheme="outline">
                  </span><a [href]="'mailto:jobs@digitalklang.de'">jobs&#64;digitalklang.de</a></span><br />
                  <span nz-icon nzType="phone" nzTheme="outline"></span><a href="tel:030 616 718 230">030 616 718 230</a>
                </div>
              </div>
            </div>
          </div>
          <div class="contact__moreInfo">
            <span class="contact__description">Anfragen von Personalvermittlern sind nicht erwünscht und bitte auch keine Snail-Mail (Post) Bewerbungen!</span>
            <div class="contact__moreInfo__links">
              <span>Weitere Informationen:</span>
              <a href="https://www.facebook.com/people/digitalklang/100067096763459/">Facebook</a>
              <a href="https://www.xing.com/pages/digitalklangcreatingsolutions">XING</a>
              <a href="https://www.kununu.com/de/digitalklang-creating-solutions1">Kununu</a>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div nz-col nzXs="24" nzMd="12">
      <div nz-row>
        <div nz-col nzXs="24" class="contact__form">
          <app-contact-sub-form></app-contact-sub-form>
        </div>
      </div>
    </div>
  </div>
</section>