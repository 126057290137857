import { HttpClientModule, provideHttpClient, withFetch } from '@angular/common/http';
import { APP_ID, APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { AppInitializer } from 'src/app-initializer';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import de from '@angular/common/locales/de';
import { NgxTurnstileModule } from "ngx-turnstile";

import { NgZorroAntdModule } from './ng-zorro-antd.module';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { de_DE } from 'ng-zorro-antd/i18n';
// import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';

import { AppComponent } from './app.component';

// layout
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';

// home
import { HomeComponent } from './home/home.component';
import { HomeHeaderComponent } from './home/home-header.component';
import { HomeDnaComponent } from './home/home-dna.component';
import { HomeServicesComponent } from './home/home-services.component';
import { HomeTechnologiesComponent } from './home/home-technologies.component';
import { HomeReferencesComponent } from './home/home-references.component';
import { HomeCareerComponent } from './home/home-career.component';

//impressum
import { ImpressumComponent } from './impressum/impressum.component';

//privacy
import { PrivacyComponent } from './privacy/privacy.component';

//contact
import { ContactComponent } from './contact/contact.component';

//services
import { ScrumComponent } from './service/scrum/scrum.component';

//sub-services
import { SubServiceComponent } from './service/sub-service/sub-service.component';
import { SubServiceHeadlineComponent } from './service/sub-service/sub-service-headline/sub-service-headline.component';
import { SubServiceInfoPanelComponent } from './service/sub-service/sub-service-info-panels/sub-service-info-panel.component';

// tiles
import { TilesComponent } from './tiles/tiles.component';

// references
import { ReferenceComponent } from './reference/reference.component';
import { ReferencesComponent } from './reference/references/references.component';
import { CustomersComponent } from './reference/customers/customers.component';
import { DetailComponent } from './reference/detail/detail.component';

// about us
import { AboutUsComponent } from './about-us/about-us.component';
import { AboutCompanyComponent } from './about-us/about-company/about-company.component'
import { ManagementComponent } from "./about-us/management/management.component";

// career
import { CareerComponent } from './career/career.component';
import { CareerCareerComponent } from './career/career-headline/career-headline.component';
import { ContactPersonComponent } from './career/contact-person/contact-person.component';
import { JobComponent } from './career/job/job.component';

// shared
import { TestimonialsComponent } from 'src/shared/components/testimonials/testimonials.component';
import { FactsComponent } from 'src/shared/components/facts/facts.component';
import { ReferenceCardComponent } from 'src/shared/components/reference-card/reference-card.component';
import { ContactSubFormComponent } from '../shared/components/contact-form/contact-sub-form/contact-sub-form.component';
import { ContactFormComponent } from '../shared/components/contact-form/contact-form.component';
import { LoadingSpinnerComponent } from 'src/shared/components/loading-spinner/loading-spinner.component';

// directives
import { NewTabDirective } from './new-tab.directive';
import { LazyLoadImageDirective } from './lazy-load-image.directive';

// pipes
import { SortByPipe } from './pipes/sort-by.pipe';

registerLocaleData(de);

@NgModule({
  declarations: [
    AppComponent,
    // layout
    HeaderComponent,
    FooterComponent,
    // home
    HomeComponent,
    HomeHeaderComponent,
    HomeDnaComponent,
    HomeServicesComponent,
    HomeTechnologiesComponent,
    HomeReferencesComponent,
    HomeCareerComponent,
    // impressum
    ImpressumComponent,
    //contact
    ContactComponent,
    // privacy
    PrivacyComponent,
    // sub-services
    SubServiceComponent,
    SubServiceHeadlineComponent,
    SubServiceInfoPanelComponent,
    // tiles
    TilesComponent,
    // references
    ReferenceComponent,
    ReferencesComponent,
    CustomersComponent,
    DetailComponent,
    // about us
    AboutUsComponent,
    AboutCompanyComponent,
    ManagementComponent,
    // scrum
    ScrumComponent,
    // career
    CareerComponent,
    CareerCareerComponent,
    JobComponent,
    ContactPersonComponent,
    // shared
    ReferenceCardComponent,
    FactsComponent,
    AboutUsComponent,
    TestimonialsComponent,
    ContactSubFormComponent,
    ContactFormComponent,
    LoadingSpinnerComponent,
    // directives
    NewTabDirective,
    LazyLoadImageDirective,
    // pipes
    SortByPipe
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    NgZorroAntdModule,
    ReactiveFormsModule,
    NgxTurnstileModule,
    // RecaptchaV3Module
  ],
  providers: [
    {provide: APP_ID, useValue:"serverApp"},
    {
      provide: APP_INITIALIZER,
      useFactory: (appInitializer: AppInitializer) => appInitializer.init(),
      deps: [AppInitializer],
      multi: true,
    },
    { provide: NZ_I18N, useValue: de_DE },
    // { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LcnTb8bAAAAAFmB24I2MFzXtfuvul_CChS8NpB4' },
    provideHttpClient(withFetch())
    // provideClientHydration()
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
