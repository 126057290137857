import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivationEnd, Router } from '@angular/router';
import { LoadingService } from './services/loading.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
  isBrowser = false;
  isLoading = true;
  isKarrierePage = false;
  
  isContactFormularDisabled = false;

  constructor(
    private titleService: Title, 
    private router: Router, 
    @Inject(PLATFORM_ID) platformId: Object, 
    private loadingService: LoadingService,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    
    this.router.events
      .pipe(filter(event => event instanceof ActivationEnd))
      .subscribe((data: ActivationEnd) => {
        let title = data.snapshot.data.title || '';
        this.titleService.setTitle(title);
        this.isKarrierePage = data.snapshot.url.length > 0 && data.snapshot.url[0].path == "karriere";
        this.isContactFormularDisabled = data.snapshot.data.title == "Datenschutz" || data.snapshot.data.title == "Impressum"
      });
  }

  ngOnInit(): void {
    this.loadingService.isLoading$.subscribe((loading) => {
      setTimeout(() => {
        this.isLoading = loading;
      });
    });
  }

  onActivate() {
    if(this.isBrowser) {
      window.setTimeout(() => {
        window.scroll(0, 0);
      });
    }
  }
}
