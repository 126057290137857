<div class="scrum" *ngIf="scrum">
    <div nz-row nzGutter="18">
        <div nz-col nzXs="24" nzSm="12">
            <h2>{{scrum.title}}</h2>
        </div>
        <div nz-col nzXs="24" nzSm="12">
            <div innerHtml="{{scrum.text}}"></div>
        </div>
    </div>
    <div nz-row>
        <div class="scrum__image" nz-col nzXs="0" nzSm="24">
            <img src="{{scrum.logo.url}}">
        </div>
    </div>
    <div nz-row>
        <h2 nz-col nzXs="0" nzSm="24">Bestandteile des SCRUM-Prozesses</h2>
    </div>
    <div class="methods">
        <div class="method" nz-row nzGutter="18" *ngFor="let item of _services; let last = last">
            <div class="method__name" nz-col nzXs="24" nzSm="12">
                <h3>{{item.name}}</h3>
            </div>
            <div class="method__teaser" nz-col nzXs="24" nzSm="12">
                <div innerHtml="{{item.teaser}}"></div>
            </div>
            <nz-divider [class]="{ 'last-service-entry': last }"></nz-divider>
        </div>
    </div>
</div>
