import { Component, OnInit } from '@angular/core';
import { SeoService } from 'src/app/services/seo.service';
import { AboutPage, DigitalklangService, HomePage, Image } from 'src/shared/wordpress/digitalklang.service';

@Component({
  selector: 'app-about-company',
  templateUrl: './about-company.component.html',
  styleUrls: ['./about-company.component.less']
})
export class AboutCompanyComponent implements OnInit {

  team_content = {} as AboutPage;
  about_texts = {} as HomePage;

  constructor(private _wordpressService: DigitalklangService, private seoService: SeoService) { }

  ngOnInit(): Promise<void> {
    return this.loadData();
  }

  private async loadData(): Promise<void> {
    try {
      this.team_content = await this._wordpressService.getAboutPage();
      this.about_texts = await this._wordpressService.getHomePage();

      if (this.team_content.about_meta_title && this.team_content.about_meta_description) {
        this.seoService.setMetaTags(
          this.team_content.about_meta_title,
          this.team_content.about_meta_description
        );
      }
    } catch (error) {
      console.error('Error loading data', error);
    }
  }
}
