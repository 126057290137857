import { Component, OnInit } from '@angular/core';
import { HomePage, DigitalklangService } from 'src/shared/wordpress/digitalklang.service';
import { SeoService } from '../services/seo.service';

@Component({
  selector: 'app-home-dna',
  templateUrl: './home-dna.component.html',
  styleUrls: ['./home-dna.component.less']
})
export class HomeDnaComponent implements OnInit {

  texts = {} as HomePage;

  constructor(private _wordpressService: DigitalklangService, private seoService: SeoService) {}

  ngOnInit(): Promise<void> {
    return this.loadHomePageTexts();
  }

  private async loadHomePageTexts(): Promise<void> {
    this.texts = await this._wordpressService.getHomePage();

    if (this.texts.dna_meta_description && this.texts.dna_meta_title) {
      this.seoService.setMetaTags(
        this.texts.dna_meta_title,
        this.texts.dna_meta_description
      );
    }
  }

}
