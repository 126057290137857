<div class="container-testimonial" *ngIf="quote">
  <div nz-row nzJustify="space-around" nzAlign="middle" class="container-testimonial-content">
    <div nz-col nzSpan="24">
      <div nz-row [nzGutter]="[32, 32]" class="quote">
        <div nz-col nzFlex="1 1 70%">
          <h2>„{{ quote.quote }}“</h2>
          <div class="quote__author">{{ quote.author }}</div>
        </div>
      </div>
    </div>
  </div>
    <span class="double-quote">”</span>
</div>
