import { Component, OnInit } from '@angular/core';
import { DigitalklangService, Management } from 'src/shared/wordpress/digitalklang.service';

@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.less']
})
export class ManagementComponent implements OnInit {

  management: Management[] = [];

  constructor(private _wordpressService: DigitalklangService) { }

  ngOnInit(): Promise<void> {
    return this.loadManagementData();
  }

  private async loadManagementData(): Promise<void> {
    try {
      this.management = await this._wordpressService.getManagements();
    } catch (error) {
      console.error('Error loading management data', error);
    }
  }

  isEmail(str: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(str);
  }
}
