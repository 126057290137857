<div class="home-technologies">
  <h2>Technologien</h2>
  <div nz-row class="carousels">
    <div nz-col nzXs="24" nzMd="0" nzLg="0" nzXl="0">
      <nz-carousel>
        <div nz-carousel-content *ngFor="let page of technologiesXs">
          <ng-template [ngTemplateOutlet]="technologies" [ngTemplateOutletContext]="{technologies: page, class: 'technologies--xs'}"></ng-template>
        </div>
      </nz-carousel>
    </div>

    <div nz-col nzXs="0" nzMd="24" nzLg="24" nzXl="0">
      <nz-carousel>
        <div nz-carousel-content *ngFor="let page of technologiesLg">
          <ng-template [ngTemplateOutlet]="technologies" [ngTemplateOutletContext]="{technologies: page, class: 'technologies--lg'}"></ng-template>
        </div>
      </nz-carousel>
    </div>

    <div nz-col nzXs="0" nzMd="0" nzLg="0" nzXl="24">
      <ng-template [ngTemplateOutlet]="technologies" [ngTemplateOutletContext]="{technologies: technologiesXl, class: 'technologies--xl'}"></ng-template>
    </div>
  </div>

  <ng-template #technologies let-technologies="technologies" let-class="class">
    <div class="technologies" [class]="class">
      <div class="technology-wrapper" *ngFor="let technology of technologies">
        <div class="technology">
          <div class="technology__icon" *ngIf="technology.image.url">
            <img [src]="technology.image.url" [alt]="technology.image.alt">
          </div>
          <div class="technology__title">
            <p>{{ technology.name }}</p>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
