import { Component, AfterViewInit, ElementRef, Renderer2, Input } from '@angular/core';
import { InfoPanel } from 'src/app/models/InfoPanelnterface';
import { InfoText } from 'src/shared/wordpress/digitalklang.service';
import { NzCarouselComponent } from 'ng-zorro-antd/carousel';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-sub-service-info-panel',
  templateUrl: './sub-service-info-panel.component.html',
  styleUrls: ['./sub-service-info-panel.component.less']
})
export class SubServiceInfoPanelComponent implements AfterViewInit {

  @Input() infoTexts: InfoText[];
  @Input() headerText: string;

  isLoading: boolean = false;

  constructor(private el: ElementRef, private renderer: Renderer2, private loadingService: LoadingService) {
  }

  ngOnInit(): void {
    this.loadingService.isLoading$.subscribe((loading) => {
      this.isLoading = loading;
    });
  }

  ngAfterViewInit() {
    const inViewPanels = this.el.nativeElement.querySelectorAll('.sub-service-info-panel-content');
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting && !this.isLoading) {
          this.renderer.addClass(entry.target, 'in-view');
          observer.unobserve(entry.target); // Optionally stop observing once in view
        }
      });
    }, { threshold: 0.1 }); // Adjust threshold as necessary

    inViewPanels.forEach((text: HTMLElement) => {
      observer.observe(text);
    });
  }

  scrollToContactArea() {
    if(typeof document == "undefined"){
      return;
    }
    const contactArea = document.getElementById('contact-area');
    if (contactArea) {
      contactArea.scrollIntoView({ behavior: 'smooth' });
    }
  }
}

