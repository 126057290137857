<div class="home-references">
  <h2 class="title">Referenzen</h2>
  <div nz-row [nzGutter]="[80, 80]" class="references">
    <div nz-col nzXs="24" nzMd="24" [nzXl]="first ? 24 : 12" class="reference-wrapper" [class.reference-wrapper--full]="first" *ngFor="let reference of references; let first = first;">
      <app-reference-card [reference]="reference"></app-reference-card>
    </div>
    <div class="button-container" nz-col nzXs="24">
      <button class="all-references" nz-button nzType="primary" nzSize="large" routerLink="/referenzen" aria-label="Zeigt alle Referenzen">Alle Referenzen</button>
    </div>
  </div>
</div>
