import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReferenceComponent } from './reference/reference.component';
import { ContactComponent } from './contact/contact.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { CareerComponent } from './career/career.component';
import { HomeComponent } from './home/home.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { SubServiceComponent } from './service/sub-service/sub-service.component';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        { 
          path: '', 
          component: HomeComponent, 
          data: { lightMode: true, title: 'Ihre Experten für Softwareentwicklung, Künstliche Intelligenz & Innovative Technologien', metaTitle: 'Ihre Experten für Softwareentwicklung, Künstliche Intelligenz & Innovative Technologien', metaDescription: 'Loollo Spezialisiert auf digitale Lösungen: Portal- und Anwendungsentwicklung, Microsoft-Integration, KI-Technologien und Cybersecurity - für Ihren Geschäftserfolg.' } 
        },
        {
          path: 'ueber-uns',
          component: AboutUsComponent,
          data: { title: 'digitalklang - Ihr Experte für User Centered Design & agile Softwareentwicklung', metaTitle: 'digitalklang - Ihr Experte für User Centered Design & agile Softwareentwicklung', metaDescription: 'digitalklang bietet innovative IT-Lösungen und Prozessharmonisierung. Vertrauen Sie auf unser erfahrenes Team, das Sie von der Anforderungsanalyse bis zur Umsetzung begleitet.' },
        },
        {
          path: 'karriere',
          component: CareerComponent,
          data: { title: 'Karriere bei digitalklang - Werde Teil unseres dynamischen IT-Teams', metaTitle: 'Karriere bei digitalklang - Werde Teil unseres dynamischen IT-Teams', metaDescription: 'Entdecke spannende Karrieremöglichkeiten bei digitalklang. Arbeite in agilen Scrum-Teams mit Home-Office, flexiblen Arbeitszeiten und attraktiven Benefits.' },
        },
        {
          path: 'kontakt',
          component: ContactComponent,
          data: { title: 'Kontaktieren Sie digitalklang - Ihr Partner für maßgeschneiderte IT-Lösungen', metaTitle: 'Kontaktieren Sie digitalklang - Ihr Partner für maßgeschneiderte IT-Lösungen', metaDescription: 'Haben Sie ein digitales Projekt? Kontaktieren Sie digitalklang für unverbindliche Beratung. Wir freuen uns auf Ihre individuellen Anforderungen.' },
        },
        {
          path: 'impressum',
          component: ImpressumComponent,
          data: { title: 'Impressum - Verantwortliche Informationen & Anbieterkennzeichnung | digitalklang', metaTitle: 'Impressum - Verantwortliche Informationen & Anbieterkennzeichnung | digitalklang', metaDescription: 'Das Impressum von digitalklang. Hier finden Sie alle wichtigen Informationen über Anbieterkennzeichnung, rechtliche Hinweise und die verantwortlichen Personen hinter dieser Website.' },
        },
        {
          path: 'datenschutz',
          component: PrivacyComponent,
          data: { title: 'Datenschutzerklärung - Ihre Daten in sicheren Händen | digitalklang', metaTitle: 'Datenschutzerklärung - Ihre Daten in sicheren Händen | digitalklang', metaDescription: 'Erfahren Sie, wie wir bei digitalklang Ihre persönlichen Daten gemäß DSGVO schützen und verarbeiten. Alle Informationen zu Datenerhebung, Speicherung und Ihren Rechten.' },
        },
        {
          path: 'portalentwicklung',
          component: SubServiceComponent,
          data: { title: 'Effektive Portalentwicklung - Maßgeschneiderte Webplattformen | digitalklang', metaTitle: 'Effektive Portalentwicklung - Maßgeschneiderte Webplattformen | digitalklang', metaDescription: 'Effiziente Portalentwicklung: Flexibel, benutzerfreundlich und DSGVO-konform. Unsere Experten gestalten leistungsstarke Webportale mit modernem UI-/UX-Design und CMS.' },
        },
        {
          path: 'digitalberatung',
          component: SubServiceComponent,
          data: { title: 'Ganzheitliche Digitalberatung - Effizienz durch Digitalisierung | digitalklang', metaTitle: 'Ganzheitliche Digitalberatung - Effizienz durch Digitalisierung | digitalklang', metaDescription: 'Optimieren Sie Ihre Geschäftsprozesse mit digitalklang. Wir bieten Digitalberatung, Automatisierungslösungen und Change Management für nachhaltige Transformation.' },
        },
        {
          path: 'anwendungsentwicklung',
          component: SubServiceComponent,
          data: { title: 'Individuelle Anwendungsentwicklung - Maßgeschneiderte Softwarelösungen | digitalklang', metaTitle: 'Individuelle Anwendungsentwicklung - Maßgeschneiderte Softwarelösungen | digitalklang', metaDescription: 'digitalklang bietet maßgeschneiderte Anwendungsentwicklung und IT-Modernisierung. Unsere agile Entwicklung sorgt für schnelle, zukunftssichere Softwarelösungen.' },
        },
        {
          path: 'microsoft-entwicklung',
          component: SubServiceComponent,
          data: { title: 'Microsoft Entwicklung - Maßgeschneiderte Lösungen mit Azure & .NET | digitalklang', metaTitle: 'Microsoft Entwicklung - Maßgeschneiderte Lösungen mit Azure & .NET | digitalklang', metaDescription: 'Nahtlose Microsoft 365-Integration und Cloud-Entwicklung mit Azure. Maßgeschneiderte Softwarelösungen für Ihr Unternehmen - sicher, effizient und zukunftssicher.' },
        },
        {
          path: 'cybersecurity',
          component: SubServiceComponent,
          data: { title: 'Umfassende Cybersecurity-Beratung - Maßgeschneiderte Schutzstrategien | digitalklang', metaTitle: 'Umfassende Cybersecurity-Beratung - Maßgeschneiderte Schutzstrategien | digitalklang', metaDescription: 'Mit digitalklang stärken Sie Ihre Cyber-Resilienz. Wir bieten umfassende Sicherheitsanalysen, maßgeschneiderte Architekturen und schnelle Notfallplanung für Ihre IT-Sicherheit.' },
        },
        {
          path: 'kuenstliche-intelligenz',
          component: SubServiceComponent,
          data: { title: 'Effiziente KI-Entwicklung - Automatisierung und Datenanalyse für Ihr Unternehmen', metaTitle: 'Effiziente KI-Entwicklung - Automatisierung und Datenanalyse für Ihr Unternehmen', metaDescription: 'Effiziente KI-Entwicklung bei digitalklang: Automatisierung, personalisierte Anwendungen und smarte Datenanalyse für eine nachhaltige digitale Transformation.' },
        },
        {
          path: 'referenzen',
          component: ReferenceComponent,
          data: { title: 'Unsere Referenzen - Portalentwicklung, Software & IT-Consulting | digitalklang', metaTitle: 'Unsere Referenzen - Portalentwicklung, Software & IT-Consulting | digitalklang', metaDescription: 'Erfahren Sie mehr über unsere erfolgreichen IT-Projekte, darunter maßgeschneiderte Portale, Softwareentwicklung und IT-Consulting.' },
        },
        {
          path: 'über-uns',
          redirectTo: 'ueber-uns',
          pathMatch: 'full'
        },
        {
          path: 'über-uns/:any',
          redirectTo: 'ueber-uns',
          pathMatch: 'full'
        },
        {
          path: 'leistungen',
          redirectTo: '/',
          pathMatch: 'full'
        },
        {
          path: 'leistungen/plattform-entwicklung',
          redirectTo: 'portalentwicklung',
          pathMatch: 'full'
        },
        {
          path: 'leistungen/softwareentwicklung',
          redirectTo: 'anwendungsentwicklung',
          pathMatch: 'full'
        },
        {
          path: 'leistungen/it-consulting',
          redirectTo: 'digitalberatung',
          pathMatch: 'full'
        },
        {
          path: 'leistungen/support',
          redirectTo: 'digitalberatung',
          pathMatch: 'full'
        },
        {
          path: 'leistungen/betriebdevops',
          redirectTo: 'digitalberatung',
          pathMatch: 'full'
        },
        {
          path: 'technologien',
          redirectTo: '/',
          pathMatch: 'full'
        },
        {
          path: 'technologien/webentwicklung',
          redirectTo: 'portalentwicklung',
          pathMatch: 'full'
        },
        {
          path: 'technologien/mobile',
          redirectTo: 'anwendungsentwicklung',
          pathMatch: 'full'
        },
        {
          path: 'technologien/desktop',
          redirectTo: 'anwendungsentwicklung',
          pathMatch: 'full'
        },
        {
          path: 'technologien/datenbank',
          redirectTo: 'cybersecurity',
          pathMatch: 'full'
        },
        {
          path: '**',
          redirectTo: '/',
          pathMatch: 'full'
        }
      ],
      { scrollPositionRestoration: 'enabled', initialNavigation: 'enabledNonBlocking' }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
