import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loadingSubject = new BehaviorSubject<boolean>(true);
  private activeRequests = 0;

  isLoading$ = this.loadingSubject.asObservable();

  setLoading(isLoading: boolean): void {
    if (isLoading) {
      this.activeRequests++;
      this.loadingSubject.next(true);
    } else {
      this.activeRequests--;
      if (this.activeRequests <= 0) {
        this.activeRequests = 0; // Sicherheitsmaßnahme
        this.loadingSubject.next(false);
      }
    }
  }
}
