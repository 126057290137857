import { Component, Input, OnInit } from '@angular/core';
import { ConsoleSqlOutline } from '@ant-design/icons-angular/icons';
import {CareerPage, DigitalklangService } from 'src/shared/wordpress/digitalklang.service';

@Component({
  selector: 'app-career-headline',
  templateUrl: './career-headline.component.html',
  styleUrls: ['./career-headline.component.less']
})
export class CareerCareerComponent {

  @Input() texts: CareerPage;

  constructor() { }
}
