<div class="footer">
    <div nz-row class="infos">
        <div nz-col nzXs="12" nzSm="12" nzXl="6" class="infos__nav-links">
            <img class="logo-digitalklang" alt="digitalklang logo" src="../../assets/logos/digitalklang.svg">

            <div class="infos__nav-links__contact">
                <div>E-Mail: <a href="mailto:info@digitalklang.de">info&#64;digitalklang.de</a></div>
                <div>Tel:
                    <span class="mobile-only-tel">
                        <a href="tel:+4930616718230">+49 30 616 718 23 0</a>
                        <span>+49 30 616 718 23 0</span>
                    </span>
                </div>
            </div>

            <div><a routerLink="datenschutz">Datenschutz</a></div>
            <div><a routerLink="impressum">Impressum</a></div>
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="6" class="infos__socials">
            <div>
                <a href="https://www.facebook.com/digitalklang-117740424981480/?ref=br_rs">
                    <img src="../../assets/icons/facebook.svg" alt="facebook" title="facebook">
                </a>
                <a href="https://www.xing.com/companies/digitalklangcreatingsolutions">
                    <img src="../../assets/icons/xing.svg" alt="XING" title="XING">
                </a>
                <a href="https://www.linkedin.com/company/digitalklang">
                    <img src="../../assets/icons/linkedin.svg" alt="LinkedIn" title="LinkedIn">
                </a>
            </div>
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzXl="6" class="infos__addresses">
            <div><b>Hauptsitz: Berlin</b></div>
            <div>Aufbau Haus</div>
            <div>Prinzenstraße 84 Aufgang 2</div>
            <div>10969 Berlin</div>
            <div>Tel:
                <span class="mobile-only-tel">
                    <a href="tel:+4930616718230">+49 30 616 718 23 0</a>
                    <span>+49 30 616 718 23 0</span>
                </span>
            </div>
            <br>
            <div><b>Standort: Hamburg</b></div>
            <div>Sternstraße 106</div>
            <div>20357 Hamburg</div>
            <div>Tel:
                <span class="mobile-only-tel">
                    <a href="tel:+49 40 7070 6622">+49 40 7070 6622</a>
                    <span>+49 40 7070 6622</span>
                </span>
            </div>
        </div>
        <div nz-col nzXs="24" nzSm="24" nzMd="12" nzXl="6" class="infos__addresses">
            <div><b>Standort: München</b></div>
            <div>Rabenkopfstr. 44</div>
            <div>81545 München</div>
            <div>Tel:
                <span class="mobile-only-tel">
                    <a href="tel:+49 89 625 35 38">+49 89 625 35 38</a>
                    <span>+49 89 625 35 38</span>
                </span>
            </div>
            <br>
            <br class="infos__addresses__plus__linebreak">
            <div><b>Standort: Düsseldorf</b></div>
            <div>Quirinstraße 1B</div>
            <div>47877 Willich</div>
        </div>
    </div>
    <nz-divider></nz-divider>
    <div nz-row nzJustify="space-around" [nzGutter]="[16, 24]" class="infos__logos">
        <div nz-col nzFlex="160px">
            <img src="../../assets/logos/stempel_iso_9001_60.png" alt="ISO 9001:2015" title="ISO 9001:2015">
        </div>
        <div nz-col nzFlex="160px">
            <img src="../../assets/logos/stempel_iso_27001_60.png" alt="ISO/IEC 27001:2024" title="ISO/IEC 27001:2024">
        </div>
        <div nz-col nzFlex="140px">
            <img src="../../assets/logos/bitkom_60.png" alt="Bitkom" title="Bitkom">
        </div>
        <div nz-col nzFlex="110px">
            <img src="../../assets/logos/microsoft-partner_60.png" alt="Microsoft Partner" title="Microsoft Partner">
        </div>
        <div nz-col nzFlex="90px">
            <img src="../../assets/logos/kununu_60.png" alt="Kununu" title="Kununu">
        </div>
    </div>
</div>
