import { Component, OnInit, ViewChild } from '@angular/core';
import { ReferencesComponent } from './references/references.component';
import { LoadingService } from '../services/loading.service';
import { SeoService } from '../services/seo.service';
import { DigitalklangService, ReferencePage } from 'src/shared/wordpress/digitalklang.service';

@Component({
  selector: 'reference',
  templateUrl: './reference.component.html',
  styleUrls: ['./reference.component.less']
})
export class ReferenceComponent implements OnInit {

  @ViewChild(ReferencesComponent, { static: true })
  referencesComponent: ReferencesComponent;
  referencePage = {} as ReferencePage;

  constructor(private loadingService: LoadingService, private seoService: SeoService, private _wordpressService: DigitalklangService) { }

  ngOnInit(): void {
    this._wordpressService.getReferencePage().then(references => {
      if (references.references_meta_title && references.references_meta_description) {
        this.seoService.setMetaTags(
          references.references_meta_title,
          references.references_meta_description
        );
      }
    });
    this.waitForData();
  }

  private async waitForData(): Promise<void> {
    try {
      this.loadingService.setLoading(true);
      await Promise.all([
        this.referencesComponent.ngOnInit()
      ]);


      this.loadingService.setLoading(false);
    } catch (error) {
      console.error('Error during data loading', error);
      this.loadingService.setLoading(false);
    }
  }
}
