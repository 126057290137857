import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { Reference, DigitalklangService } from "../../../shared/wordpress/digitalklang.service";
import { SeoService } from 'src/app/services/seo.service';
@Component({
  selector: 'references',
  templateUrl: './references.component.html',
  styleUrls: ['./references.component.less']
})
export class ReferencesComponent implements OnInit {
  
  references: Reference[] = [];

  constructor(private wordpressService: DigitalklangService) { }

  ngOnInit(): Promise<void> {
    return this.loadReferences();
  }

  private async loadReferences(): Promise<void> {
    this.references = await this.wordpressService.getReferences();
  }
}
