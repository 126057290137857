import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild, Input } from '@angular/core';
import { LoadingService } from 'src/app/services/loading.service';
import { Fact, DigitalklangService } from 'src/shared/wordpress/digitalklang.service';

export interface FactWithCounter extends Fact {
  currentValue?: number; 
}

@Component({
  selector: 'app-facts',
  templateUrl: './facts.component.html',
  styleUrls: ['./facts.component.less']
})
export class FactsComponent implements OnInit {
  facts: FactWithCounter[] = [];
  @ViewChild('factContainer') factContainer: ElementRef<HTMLDivElement>;
  private animationStarted = false;

  constructor(private _wordpressService: DigitalklangService, private loadingService: LoadingService) {}

  async ngOnInit(): Promise<void> {
    this.facts = await this._wordpressService.getFactsAndFigures();
    this.facts.forEach(fact => fact.currentValue = fact.value);
  }

  ngOnChanges(): void {
    if (!this.loadingService.isLoading$) {
      setTimeout(() => {
        this.checkAndStartAnimation();
      }, 300);
    }
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    this.checkAndStartAnimation();
  }

  private checkAndStartAnimation() {
    const position = this.factContainer.nativeElement.getBoundingClientRect();

    if (position.top < window.innerHeight && position.bottom > 0 && !this.animationStarted) {
      this.animateValues();
      this.animationStarted = true;
    }
  }

  private animateValues() {
    const duration = 4000;
    const interval = 20;

    this.facts.forEach(fact => {
      let timeElapsed = 0;
      const intervalId = setInterval(() => {
        timeElapsed += interval;
        const t = Math.min(timeElapsed / duration, 1);
        const easeOutT = 1 - Math.pow(1 - t, 3);
  
        fact.currentValue = fact.value * easeOutT;
  
        if (timeElapsed >= duration) {
          fact.currentValue = fact.value;
          clearInterval(intervalId);
        }
      }, interval);
    });
  }
}
