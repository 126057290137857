import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DigitalklangService } from 'src/shared/wordpress/digitalklang.service';

@Injectable({
  providedIn: 'root',
})
export class CaptchaService {
  private siteKeySubject = new BehaviorSubject<string | null>(null);
  public siteKey = this.siteKeySubject.asObservable();

  constructor(private _digitalklangService: DigitalklangService) {
    this.loadSiteKey();
  }

  private async loadSiteKey(): Promise<void> {
    const response = await this._digitalklangService.getCaptchaSettings();
    this.siteKeySubject.next(response.site_key);
  }

  public async validateCaptcha(token: string): Promise<boolean> {
    return this._digitalklangService
      .validateCaptchaToken(token)
      .then(response => response)
      .catch(() => false);
  }
}
