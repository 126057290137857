import { Component, Input, OnInit } from '@angular/core';
import { DigitalklangService, Scrum, Service } from 'src/shared/wordpress/digitalklang.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-scrum',
  templateUrl: './scrum.component.html',
  styleUrls: ['./scrum.component.less']
})
export class ScrumComponent implements OnInit {

  private _services: Service[] = [];
  public scrum: Scrum;

  @Input() set services(value: Service[]) {
    if (value) {
      this._services = _.filter(value, ['type', 'method']);
    }
  };

  constructor(private _wordpressService: DigitalklangService) { }

  async ngOnInit(): Promise<void> {
    this.scrum = await this._wordpressService.getScrumPage();
  }
}
