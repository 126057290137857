import { Component, OnInit, ViewChild } from '@angular/core';
import {
  CareerPage,
  DigitalklangService,
} from 'src/shared/wordpress/digitalklang.service';
import { JobComponent } from './job/job.component';
import { LoadingService } from '../services/loading.service';
import { SeoService } from '../services/seo.service';

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.less'],
})
export class CareerComponent implements OnInit {

  texts = {} as CareerPage;

  @ViewChild(JobComponent, { static: true }) 
  jobComponent: JobComponent;

  constructor(
    private _digitalklangService: DigitalklangService, 
    private loadingService: LoadingService,
    private seoService: SeoService) {}

  ngOnInit(): void {
    this.waitForData();
  }

  private async waitForData(): Promise<void> {
    try {
      this.loadingService.setLoading(true);
      await Promise.all([
        this.loadCareerTexts(),
        this.jobComponent.ngOnInit()
      ]);
      this.loadingService.setLoading(false);
    } catch (error) {
      console.error('Error during data loading', error);
      this.loadingService.setLoading(false);
    }
  }

  private async loadCareerTexts(): Promise<void> {
    this.texts = await this._digitalklangService.getCareerPage();

    if (this.texts.career_meta_title && this.texts.career_meta_description) {
      this.seoService.setMetaTags(
        this.texts.career_meta_title,
        this.texts.career_meta_description
      );
    }
  }
}
