import { Component, OnInit } from '@angular/core';
import { Job, DigitalklangService } from 'src/shared/wordpress/digitalklang.service';

@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.less']
})
export class JobComponent implements OnInit {

  jobs: Job[] = [];

  constructor(private _wordpressService: DigitalklangService) { }

  ngOnInit(): Promise<void> {
    return this.loadJobs();
  }

  private async loadJobs(): Promise<void> {
    try {
      this.jobs = await this._wordpressService.getJobs();
    } catch (error) {
      console.error('Error loading jobs', error);
    }
  }
}
