import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';

interface ContactText {
  title: string;
  intro: string;
  description: string;
  greeting: string;
  person: {
    name: string;
    role: string;
    email: string;
    phone: string;
  };
}

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.less'],
})
export class ContactFormComponent {
  @Input() isKarrierePage: boolean = false;

  contactText: ContactText;
}
