import { Component, OnInit } from '@angular/core';
import { PrivacyPage, DigitalklangService } from 'src/shared/wordpress/digitalklang.service';
import { LoadingService } from '../services/loading.service';
import { SeoService } from '../services/seo.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.less']
})
export class PrivacyComponent implements OnInit {

  privacy = {} as PrivacyPage;

  constructor(
    private _wordpressService: DigitalklangService, 
    private loadingService: LoadingService,
    private seoService: SeoService) { }

  async ngOnInit(): Promise<void> {
    this.privacy = await this._wordpressService.getPrivacyPage();
    if (this.privacy.privacy_meta_title && this.privacy.privacy_meta_description) {
      this.seoService.setMetaTags(
        this.privacy.privacy_meta_title,
        this.privacy.privacy_meta_description
      );
    }
    this.loadingService.setLoading(true);
    setTimeout(() => this.loadingService.setLoading(false), 100);
  }
}
