<div class="home-career">
  <h2 style="color: #fff">{{ texts.career_title }}</h2>
  <div nz-row>
    <div nz-col [nzXs]="{ span: 24 }">
      <div class="career__text">
        <div [innerHtml]="texts.career_body"></div>
      </div>
    </div>
    <div class="career__button">
      <button nz-button nzType="primary" nzSize="large" routerLink="/karriere" aria-label="Zeigt alle offenen Stellen bei digitalklang">Offene Stellen</button>
    </div>
  </div>
</div>
