import { Inject, Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConsts } from '@shared/AppConsts';
import { environment } from './environments/environment';
import { DOCUMENT, PlatformLocation } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AppInitializer {
  constructor(
    private _platformLocation: PlatformLocation,
    private _httpClient: HttpClient,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  init(): () => Promise<boolean> {
    return () => {
      return new Promise<boolean>((resolve, reject) => {
        AppConsts.appBaseHref = this.getBaseHref();
        const appBaseUrl = this.getDocumentOrigin() + AppConsts.appBaseHref;
        this.getApplicationConfig(appBaseUrl, () => resolve(true));
      });
    };
  }

  private getBaseHref(): string {
    const baseUrl = this._platformLocation.getBaseHrefFromDOM();
    if (baseUrl) {
      return baseUrl;
    }

    return '/';
  }

  private getDocumentOrigin(): string {
    if (!this._document.location.origin) {
      const port = this._document.location.port
        ? ':' + this._document.location.port
        : '';
      return (
        this._document.location.protocol +
        '//' +
        this._document.location.hostname +
        port
      );
    }

    return this._document.location.origin;
  }

  private getApplicationConfig(appRootUrl: string, callback: () => void) {
    this._httpClient
      .get<any>(`${appRootUrl}assets/${environment.appConfig}`)
      .subscribe((response) => {
        AppConsts.appBaseUrl = response.appBaseUrl;
        AppConsts.wordpressBaseUrl = response.wordpressBaseUrl;

        callback();
      });
  }
}
