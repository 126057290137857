<h2>Management</h2>
<div class="managementContainer">
  <div *ngFor="let person of management" class="managementPerson">
    <div  class="managementImage" >
      <img src="{{person.image.url}}" [alt]="person.image.alt" class="image" />
    </div>

    <div class="managementPersonInfo">
      <h3>{{person.full_name}}</h3>
      <h4 class="managementPersonInfo__profession">{{person.profession}}</h4>
      <h4>{{person.occupation}}</h4>
    </div>
    <div class="linkButtonContainer">
      <div *ngFor="let link of [person.links1, person.links2, person.links3]">
        <div *ngIf="link.image && link.url">
          <a *ngIf="isEmail(link.url)" [href]="'mailto:' + link.url">
            <img [src]="link.image.url" [alt]="link.image.alt" class="linkButton"/>
          </a>
          <a *ngIf="!isEmail(link.url)" [href]="link.url">
            <img [src]="link.image.url" [alt]="link.image.alt" class="linkButton"/>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

