import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../services/loading.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.less'],
})
export class ContactComponent implements OnInit {

  constructor(private loadingService: LoadingService) {}

  ngOnInit(): void {
    this.loadingService.setLoading(true);
    setTimeout(() => this.loadingService.setLoading(false), 100);
  }
}
