<div class="home">
  <section class="home__header full-width">
    <app-home-header></app-home-header>
  </section>
  <section class="home__dna">
    <app-home-dna></app-home-dna>
  </section>
  <section class="home__facts">
    <app-facts></app-facts>
  </section>
  <section class="home__services">
    <app-home-services></app-home-services>
  </section>
  <section class="home__customers">
    <app-customers></app-customers>
  </section>
  <section class="home__technologies">
    <app-home-technologies></app-home-technologies>
  </section>
  <section class="home__careers">
    <app-home-career></app-home-career>
  </section>
</div>