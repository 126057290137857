import { Component, OnInit } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { LoadingService } from 'src/app/services/loading.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {
  isStartPage = false;
  submenuOpen = false;
  lightMode = false;
  drawerVisible = false;
  isLoading = true;

  switchStateOfDrawer(): void {
    this.drawerVisible = !this.drawerVisible;
  }

  closeDrawer(): void {
    this.drawerVisible = false;
  }

  constructor(router: Router, private loadingService: LoadingService) {
    router.events
    .subscribe((data) => {
      if (data instanceof ActivationEnd && !data.snapshot.data.preload) {
        this.lightMode = !!data.snapshot.data.lightMode;
        this.isStartPage = data.snapshot.data.title == ""; 
      }
    });
  }

  toggleSubmenu(open: boolean): void {
    this.submenuOpen = open;
  }

  ngOnInit(): void {
    this.loadingService.isLoading$.subscribe((loading) => {
      this.isLoading = loading;
    });
  }

}
