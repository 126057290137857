import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.less'],
})
export class LoadingSpinnerComponent implements OnChanges  {
  @Input() isLoading: boolean = true;
  showLoading: boolean = true;

  ngOnChanges(changes: SimpleChanges): void {
    if(typeof document == "undefined"){
      return;
    }
    this.showLoading = true;
    if (changes['isLoading'] && !changes['isLoading'].currentValue) {
      setTimeout(() => {
        const loadingElement = document.querySelector('.loading');
        if (loadingElement) {
          loadingElement.addEventListener('transitionend', () => {
            this.showLoading = false;
          });
          loadingElement.classList.add('fade-out');
        }
      }, 0);
    }
  }
}
