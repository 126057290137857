import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { Reference, DigitalklangService } from 'src/shared/wordpress/digitalklang.service';

@Component({
  selector: 'app-home-references',
  templateUrl: './home-references.component.html',
  styleUrls: ['./home-references.component.less']
})
export class HomeReferencesComponent implements OnInit {

  @Input() fetchAmount: number = 3;
  @Input() fetchId: number;
  references: Reference[] = [];

  constructor(private _wordpressService: DigitalklangService) { }

  ngOnInit(): Promise<void> {
    return this.loadReferences();
  }

  private async loadReferences(): Promise<void> {
    try {
      if(this.fetchId){
        this.references = _.chain(await this._wordpressService.getReferences())
        .filter(reference => reference.id === this.fetchId)
        .take(1)
        .value();
      }
      else{
        this.references = _.chain(await this._wordpressService.getReferences())
          .filter(reference => reference.featured)
          .take(this.fetchAmount)
          .value();
      }
    } catch (error) {
      console.error('Error loading references', error);
    }
  }
}
