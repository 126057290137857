import { Component, OnInit, ViewChild } from '@angular/core';
import { AboutCompanyComponent } from './about-company/about-company.component';
import { ManagementComponent } from './management/management.component';
import { LoadingService } from '../services/loading.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.less']
})
export class AboutUsComponent implements OnInit {

  @ViewChild(AboutCompanyComponent, { static: true })
  aboutCompanyComponent: AboutCompanyComponent;

  @ViewChild(ManagementComponent, { static: true })
  managementComponent: ManagementComponent;

  constructor(private loadingService: LoadingService) { }

  ngOnInit(): void {
    this.waitForData();
  }
  
  private async waitForData(): Promise<void> {
    try {
      this.loadingService.setLoading(true);
      await Promise.all([
        this.aboutCompanyComponent.ngOnInit(),
        this.managementComponent.ngOnInit()
      ]);
      this.loadingService.setLoading(false);
    } catch (error) {
      console.error('Error during data loading', error);
      this.loadingService.setLoading(false);
    }
  }
}
