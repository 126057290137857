import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { Testimonial, DigitalklangService, Quote } from 'src/shared/wordpress/digitalklang.service';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.less']
})

export class TestimonialsComponent implements OnInit {

  @Input() quote: Quote;

  constructor(private wordpressService: DigitalklangService) {
  }

  async ngOnInit(): Promise<void> {}

}
