import { HttpClient } from '@angular/common/http';

const WORDPRESS_API = 'wp/v2';
const ACF_API = 'acf/v3';

export abstract class WordpressService {
  constructor(protected baseUrl: string, protected client: HttpClient) {
    if (!this.baseUrl.endsWith('/')) {
      this.baseUrl += '/';
    }

    this.baseUrl += 'wp-json';
  }

  protected pages<T>(): Promise<T[]> {
    return this.client
      .get<T[]>(`${this.baseUrl}/${WORDPRESS_API}/pages`)
      .toPromise();
  }

  protected resource<T>(
    resource: string,
    maxCount: number = 100
  ): Promise<T[]> {
    return this.client
      .get<T[]>(
        `${this.baseUrl}/${WORDPRESS_API}/${resource}?per_page=${maxCount}`
      )
      .toPromise();
  }

  protected acf<T>(resource: string, maxCount: number = 100): Promise<T[]> {
    return this.client
      .get<any[]>(`${this.baseUrl}/${ACF_API}/${resource}?per_page=${maxCount}`)
      .toPromise();
  }
}
