import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { Technology, DigitalklangService } from 'src/shared/wordpress/digitalklang.service';

@Component({
  selector: 'app-home-technologies',
  templateUrl: './home-technologies.component.html',
  styleUrls: ['./home-technologies.component.less'],
})
export class HomeTechnologiesComponent implements OnInit {

  technologiesXs: Technology[][] = [];
  technologiesLg: Technology[][] = [];
  technologiesXl: Technology[] = [];

  constructor(private _wordpressService: DigitalklangService) { }

  ngOnInit(): Promise<void> {
    return this.loadTechnologies();
  }

  private async loadTechnologies(): Promise<void> {
    try {
      this.technologiesXl = (await this._wordpressService.getTechnologies()).sort((a, b) => {
        if (a.order !== undefined && b.order !== undefined) {
            if (Number(a.order) <= Number(b.order)) {
                return -1;
            } else {
                return 1;
            }
        } else if (a.order !== undefined && b.order === undefined) {
            return -1;
        } else if (a.order === undefined && b.order !== undefined) {
            return 1;
        } else {
            return 0;
        }
    });
      this.technologiesXs = this.getCarouselPages(6);
      this.technologiesLg = this.getCarouselPages(3);
    } catch (error) {
      console.error('Error loading technologies', error);
    }
  }

  private getCarouselPages(size: number): Technology[][] {
    return _.chunk(this.technologiesXl, size);
  }
}
