<div class="references">
    <section>
        <references></references>
    </section>
    <section class="references__facts">
      <app-facts></app-facts>
    </section>
    <section>
        <app-customers></app-customers>
    </section>
</div>
