<div *ngIf="service">
    <div class="subservice">
        <section>
            <app-sub-service-headline class="centered" [service]="service"></app-sub-service-headline>
        </section>
    </div>
    <section *ngIf="this.tiles && this.tiles.length > 0" class="tiles">
        <app-tiles [tiles]="this.tiles" [componentTitle]="service.tile_header_title"
            [componentContent]="service.tile_header_content"></app-tiles>
    </section>
    <section class="subservice__facts">
        <app-facts></app-facts>
    </section>
    <section *ngIf="this.infoTexts && this.infoTexts.length > 0" class="info-panels">
        <app-sub-service-info-panel [headerText]="this.service.info_text_header_title"
            [infoTexts]="this.infoTexts"></app-sub-service-info-panel>
    </section>
    <section class="subservice__references">
        <app-home-references [fetchAmount]="1" [fetchId]="this.service.reference"></app-home-references>
    </section>
    <section class="subservice__testimonials full-width">
        <app-testimonials [quote]="quote"></app-testimonials>
    </section>
</div>
