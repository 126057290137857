<div class="contact-form">
  <form nz-form nzLayout="vertical" [formGroup]="form" (ngSubmit)="submitForm()">
    <div nz-row>
      <div nz-col nzXs="24">
        <nz-form-item>
          <nz-form-label [nzFor]="'name'">Name*</nz-form-label>
          <nz-form-control nzErrorTip="Füllen Sie dieses Feld aus.">
            <input nz-input class="form-border" id="name" formControlName="name" />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row>
      <div nz-col nzXs="24">
        <nz-form-item>
          <nz-form-label [nzFor]="'email'">E-Mail*</nz-form-label>
          <nz-form-control nzErrorTip="Füllen Sie dieses Feld aus.">
            <input nz-input id="email" formControlName="email" />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row>
      <div nz-col nzXs="24">
        <nz-form-item>
          <nz-form-label [nzFor]="'telephone'">Telefon*</nz-form-label>
          <nz-form-control nzErrorTip="Füllen Sie dieses Feld aus.">
            <input nz-input id="telephone" formControlName="telephone" />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row>
      <div nz-col nzXs="24">
        <nz-form-item>
          <nz-form-label [nzFor]="'message'">Ihre Nachricht an uns*</nz-form-label>
          <nz-form-control nzErrorTip="Füllen Sie dieses Feld aus.">
            <textarea rows="2" nz-input id="message" formControlName="message"></textarea>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row>
    <nz-form-item>
      <nz-form-control nzErrorTip="Aktivieren Sie dieses Kontrollkästchen, um fortzufahren.">
        <div class="checkbox-container">
          <input
          type="checkbox"
          id="agreement"
          formControlName="agreement" 
          class="checkbox-input"/>
          <label for="agreement" class="label">
            <span>
              digitalklang verarbeitet Ihre Daten, um Ihre Anfrage zu beantworten und nimmt zu diesem Zweck auch ggf. Kontakt mit Ihnen auf.
              <a routerLink="datenschutz" target="_blank">Datenschutz</a>: Nähere Informationen in der Datenschutzerklärung.
            </span>
          </label>
        </div>
      </nz-form-control>
    </nz-form-item>
  </div>
  <div nz-row>
    <div nz-col nzXs="24">
      <nz-form-item>
        <nz-form-control>
          <ngx-turnstile *ngIf="this.siteKey" [siteKey]="this.siteKey" [theme]="'light'" (resolved)="onCaptchaResolved($event)"></ngx-turnstile>
          <button nz-button [nzLoading]="isSubmitting" [disabled]="isSubmitting || !isCaptchaValid" class="contact-form__button" nzType="primary" aria-label="Senden">Senden</button>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </form>
</div>
