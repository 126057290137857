<div class="home-services">
  <div class="header">
    <h2>{{ texts.sam_title }}</h2>
    <p [innerHTML]="texts.sam_body"></p>
  </div>
  <div nz-row [nzGutter]="[16, 16]" class="services">
    <div nz-col nzXs="24" nzLg="12" nzXl="8" *ngFor="let service of services | sortBy: 'desc':'type'">
      <a [routerLink]="service.link">
        <div class="service-container" [class]="service.type">
          <div class="service">
            <div class="service__title">
              <h3>{{ service.name }}</h3>
            </div>
            <div class="service__short_teaser">
              <p>
                {{ service.short_teaser }}
              </p>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</div>
