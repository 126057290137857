<div class="about-us">
  <section class="home__about-us">
    <app-about-company></app-about-company>
  </section>
  <section class="about-us__facts">
    <app-facts></app-facts>
  </section>
  <section class="full-width">
    <section class="about-us__management">
      <app-management></app-management>
    </section>
  </section>
  <section class="about-us__careers">
    <app-home-career></app-home-career>
  </section>
</div>