import { Component, OnInit } from '@angular/core';
import { ImpressumPage, DigitalklangService } from 'src/shared/wordpress/digitalklang.service';
import { LoadingService } from '../services/loading.service';
import { SeoService } from '../services/seo.service';

@Component({
  selector: 'app-impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.less']
})
export class ImpressumComponent implements OnInit {

  impressum = {} as ImpressumPage;

  constructor(
    private _wordpressService: DigitalklangService, 
    private loadingService: LoadingService,
    private seoService: SeoService) { }

  async ngOnInit(): Promise<void> {
    this.impressum = await this._wordpressService.getImpressumPage();
    if (this.impressum.impressum_meta_title && this.impressum.impressum_meta_description) {
      this.seoService.setMetaTags(
        this.impressum.impressum_meta_title,
        this.impressum.impressum_meta_description
      );
    }
    this.loadingService.setLoading(true);
    setTimeout(() => this.loadingService.setLoading(false), 100);
  }

}
