<div class="container-reference-customer">
  <h2>Zufriedene Kunden</h2>
  <div class="carousel-container" #carouselContainer>
    <div class="carousel-content" *ngFor="let customer of customersXl">
      <div class="icon-item">
        <img [src]="customer.image.url" [alt]="customer.image.alt">
      </div>
    </div>
  </div>
  <div #controlsContainer class="controls">
    <button class="prev" aria-label="Vorherigen Kunden anzeigen"><span nz-icon nzType="left"></span></button>
    <button class="next" aria-label="Nächsten Kunden anzeigen"><span nz-icon nzType="right"></span></button>
  </div>
</div>
