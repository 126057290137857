import { Component, OnInit, ViewChild } from '@angular/core';
import { HomeDnaComponent } from './home-dna.component';
import { HomeHeaderComponent } from './home-header.component';
import { HomeServicesComponent } from './home-services.component';
import { HomeTechnologiesComponent } from './home-technologies.component';
import { HomeCareerComponent } from './home-career.component';
import { LoadingService } from '../services/loading.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit {

  @ViewChild(HomeCareerComponent, { static: true }) homeCareerComponent: HomeCareerComponent;
  @ViewChild(HomeDnaComponent, { static: true }) homeDnaComponent: HomeDnaComponent;
  @ViewChild(HomeHeaderComponent, { static: true }) homeHeaderComponent: HomeHeaderComponent;
  @ViewChild(HomeServicesComponent, { static: true }) homeServicesComponent: HomeServicesComponent;
  @ViewChild(HomeTechnologiesComponent, { static: true }) homeTechnologiesComponent: HomeTechnologiesComponent;

  constructor(private loadingService: LoadingService) { }

  ngOnInit(): void {
    this.waitForData();
  }
  
  private async waitForData(): Promise<void> {
    try {
      this.loadingService.setLoading(true);
      await Promise.all([
        this.homeCareerComponent.ngOnInit(),
        this.homeDnaComponent.ngOnInit(),
        this.homeHeaderComponent.ngOnInit(),
        this.homeServicesComponent.ngOnInit(),
        this.homeTechnologiesComponent.ngOnInit(),
      ]);
      this.loadingService.setLoading(false);
    } catch (error) {
      console.error('Error during data loading', error);
      this.loadingService.setLoading(false);
    }
  }
}
