<nz-layout [class.ssr]="!isBrowser">

  <nz-header>
    <app-header class="header"></app-header>
  </nz-header>

  <app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>
  
  <nz-content class="content">
    <router-outlet (activate)="onActivate()"></router-outlet>
  </nz-content>

  <nz-content *ngIf="!isContactFormularDisabled" id="contact-area">
    <app-contact-form  [isKarrierePage]="isKarrierePage"></app-contact-form>
  </nz-content>

  <nz-footer class="footer">
    <app-footer class="app-footer"></app-footer>
  </nz-footer>

</nz-layout>
