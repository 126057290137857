<div class="contact-person">
    <div nz-row>
        <div nz-col nzSm="12" nzXs="0">
            <p [innerHTML]="texts.contact_person_body"></p>
        </div>
        <div nz-col class="contact-person__image" nzSm="12" nzXs="0">
            <img src="{{texts.contact_person_image?.url}}" [alt]="texts.contact_person_image['alt']">
        </div>
    </div>
    <div nz-row>
        <div nz-col nzXs="24" nzSm="0">
            <img class="contact-person__image--mobile" src="{{texts.contact_person_image?.url}}" [alt]="texts.contact_person_image['alt']">
            <span [innerHTML]="texts.contact_person_body"></span>
        </div>
    </div>
</div>