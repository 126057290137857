import { Component, Input, OnInit } from '@angular/core';
import { ConsoleSqlOutline } from '@ant-design/icons-angular/icons';
import {CareerPage, DigitalklangService } from 'src/shared/wordpress/digitalklang.service';

@Component({
  selector: 'app-contact-person',
  templateUrl: './contact-person.component.html',
  styleUrls: ['./contact-person.component.less']
})
export class ContactPersonComponent {

  @Input() texts: CareerPage;

  constructor() { }
}
