import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { CaptchaService } from 'src/app/services/captcha.service';

import {
  DigitalklangService,
  ContactInformation,
} from 'src/shared/wordpress/digitalklang.service';

@Component({
  selector: 'app-contact-sub-form',
  templateUrl: './contact-sub-form.component.html',
  styleUrls: ['./contact-sub-form.component.less'],
})
export class ContactSubFormComponent
  implements OnInit {
  form!: UntypedFormGroup;
  isSubmitting = false;
  captchaToken: string | null = null;
  isCaptchaValid: boolean = false;

  siteKey: string = null;

  constructor(
    private _fb: UntypedFormBuilder,
    private _digitalklangService: DigitalklangService,
    private _messageService: NzMessageService,
    private _captchaService: CaptchaService
  ) { }

  ngOnInit(): void {
    this.form = this._fb.group(
      {
        name: [null, [Validators.required]],
        email: [null, [Validators.required, Validators.email]],
        telephone: [null, [Validators.required]],
        message: [null, [Validators.required]],
        agreement: [false, [Validators.requiredTrue]],
      },
      { updateOn: 'change' }
    );
    this.getSiteKey();
  }

  getSiteKey(): void {
    this._captchaService.siteKey.subscribe(siteKey => {
      this.siteKey = siteKey
    });
  }

  async onCaptchaResolved(token: string): Promise<void> {
    this.captchaToken = token;
    this.isCaptchaValid = await this._captchaService.validateCaptcha(this.captchaToken);
  }

  async submitForm(): Promise<void> {
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }

    if (!this.form.valid || !this.isCaptchaValid || !this.captchaToken) {
      this._messageService.warning(
        'Bitte füllen Sie alle Felder korrekt aus und bestätigen Sie das Captcha.',
        { nzDuration: 3000 }
      );
      return;
    }
  
    this.isSubmitting = true;

    const contactInformation: ContactInformation = Object.assign(
      this.form.value
    );
    this._digitalklangService
      .sendContact(contactInformation)
      .then(() => {
        this.form.reset();
        this._messageService.success(
          'Vielen Dank für Ihre Anfrage. Wir werden uns baldmöglichst mit Ihnen in Verbindung setzen.',
          {
            nzDuration: 5000,
          }
        );
      })
      .catch(() => {
        this._messageService.error(
          'Absenden fehlgeschlagen, bitte versuchen Sie es später erneut.'
        );
      })
      .finally(() => {
        this.isSubmitting = false;
        this.form.enable();
      });
  }
}
