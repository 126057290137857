import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConsoleSqlOutline } from '@ant-design/icons-angular/icons';
import {Service, DigitalklangService } from 'src/shared/wordpress/digitalklang.service';

@Component({
  selector: 'app-sub-service-headline',
  templateUrl: './sub-service-headline.component.html',
  styleUrls: ['./sub-service-headline.component.less']
})
export class SubServiceHeadlineComponent {

  @Input() service: Service;
  // @Input() imageLoading: boolean;
  // @Output() imageLoadingChange = new EventEmitter<boolean>();

  constructor() { }

  // onImageLoad(): void{
  //   this.imageLoading = false;
  //   this.imageLoadingChange.emit(this.imageLoading);
  // }
}
