import { Component, Input, OnInit } from '@angular/core';
import { Reference } from 'src/shared/wordpress/digitalklang.service';

@Component({
  selector: 'app-reference-card',
  templateUrl: './reference-card.component.html',
  styleUrls: ['./reference-card.component.less']
})
export class ReferenceCardComponent implements OnInit {

  @Input() reference: Reference;
  @Input() size: 'small' | 'large' = 'small';
  @Input() showLogo = true;

  constructor() { }

  ngOnInit(): void {
  }

}
