import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { ReferenceCustomer, DigitalklangService } from 'src/shared/wordpress/digitalklang.service';
import { isPlatformBrowser } from '@angular/common'; // Import für isPlatformBrowser

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.less']
})
export class CustomersComponent implements OnInit {

  @ViewChild('carouselContainer', { static: true }) carouselContainer: ElementRef;
  @ViewChild('controlsContainer', { static: true }) controlsContainer: ElementRef;

  customersXl: ReferenceCustomer[] = [];

  constructor(
    private wordpressService: DigitalklangService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  async ngOnInit(): Promise<void> {
    await this.loadCustomers();
    
    if (isPlatformBrowser(this.platformId)) {
      const initSlider = async () => {
        const { tns } = await import('tiny-slider/src/tiny-slider');

        const sliderOptions: any = {
          container: this.carouselContainer.nativeElement,
          slideBy: 1,
          items: 3,
          autoplay: true,
          autoplayTimeout: 3000,
          controls: true,
          nav: false,
          center: true,
          mouseDrag: true,
          autoplayButtonOutput: false,
          controlsText: ['<', '>'],
          loop: true,
          speed: 400,
          controlsContainer: this.controlsContainer.nativeElement,
          fixedWidth: 240,
        };

        tns(sliderOptions);
      };

      setTimeout(() => {
        initSlider();
      }, 100);
    }
  }

  private async loadCustomers(): Promise<void> {
    const customers = await this.wordpressService.getReferencesCustomer();
    this.customersXl = customers;
  }
}
