<div class="tile__section">
    <div class="tile__container">
        <div class="tile__container__header">
            <h2><span class="tile__container__header__highlight">Vorteile</span> {{this.componentTitle}}</h2>
            <p [innerHTML]="this.componentContent"></p>
        </div>
    
        <div nz-row class="tile__grid" [nzGutter]="[16, 16]">
            <div nz-col nzXs="24" nzLg="12" nzXl="8" *ngFor="let tile of this.tiles" class="tile__container__container">
                <div class="tile__tile">
                    <h3>{{tile.tile_text}}</h3>
                    <p [innerHTML]="tile.tile_content"></p>
                </div>
            </div>
        </div>
        <div class="tile__container__button">
            <button nz-button nzType="primary" nzSize="large" (click)="scrollToContactArea()" aria-label="Unverbindliche Projektanfrage starten">Unverbindliche Projektanfrage starten</button>
        </div>
    </div>
</div>