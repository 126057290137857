<div class="sub-service-info-panel">
    <div class="sub-service-info-panel-container">
        <h2>
            Unser <span>Leistungsangebot</span> {{this.headerText}}
        </h2>

        <div class="sub-service-info-panel-desktop">
            <div *ngFor="let panel of this.infoTexts; let i = index" class="sub-service-info-panel">
                <div *ngIf="i % 2 === 1" class="sub-service-info-panel-content left">
                    <div class="text">
                        <h4>{{panel.info_title}}</h4>
                        <p [innerHTML]="panel.info_content"></p>
                        <div class="point"></div>
                    </div>
                    <div class="verticalSeparator"></div>
                    <img [src]="panel.info_image.url" [alt]="panel.info_image.alt">
                </div>
                <div *ngIf="i % 2 === 0" class="sub-service-info-panel-content right">
                    <img [src]='panel.info_image.url' [alt]="panel.info_image.alt">
                    <div class="verticalSeparator"></div>
                    <div class="text">
                        <h4>{{panel.info_title}}</h4>
                        <p [innerHTML]="panel.info_content"></p>
                        <div class="point"></div>
                    </div>
                </div>
                <div *ngIf="i !== this.infoTexts.length-1" class="horizontalSeparator">
                    <div class="empty"></div>
                    <div class="color"></div>
                    <div class="empty"></div>
                </div>
            </div>
        </div>
        <div class="sub-service-info-panel-mobile">
            <nz-carousel [nzDotPosition]="'bottom'">
                <div nz-carousel-content *ngFor="let panel of this.infoTexts; let i = index">
                    <div class="sub-service-info-panel-content-mobile">
                        <div class="sub-service-info-panel-content-mobile-header">
                            <img [src]='panel.info_image.url' [alt]="panel.info_image.alt">
                            <h4>{{panel.info_title}}</h4>
                        </div>
                        <p [innerHTML]="panel.info_content"></p>
                    </div>
                </div>
            </nz-carousel>
        </div>
    </div>
    <div class="sub-service-info-panel-contact" (click)="scrollToContactArea()">
        <p>Profitieren Sie von unserer Erfahrung + Expertise.</p>
        <h3>Fragen Sie unverbindlich Ihr Projekt an</h3>
        <button nz-button nzType="primary" nzSize="large" aria-label="Unverbindliche Projektanfrage starten">Unverbindliche Projektanfrage starten</button>
    </div>
</div>