import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TileContent } from 'src/app/models/TileInterface';
import { LoadingService } from 'src/app/services/loading.service';
import { SeoService } from 'src/app/services/seo.service';
import {
  Service,
  DigitalklangService,
  Quote,
  InfoText,
  TileText,
} from 'src/shared/wordpress/digitalklang.service';

@Component({
  selector: 'app-sub-service',
  templateUrl: './sub-service.component.html',
  styleUrls: ['./sub-service.component.less'],
})
export class SubServiceComponent implements OnInit {
  service: Service;
  quote: Quote;
  tiles: TileText[];
  infoTexts: InfoText[];
  imageLoading: boolean = true;

  constructor(
    private _digitalklangService: DigitalklangService, 
    private route: ActivatedRoute, 
    private loadingService: LoadingService,
    private seoService: SeoService) {}

  async ngOnInit(): Promise<void> {
    const subService = this.route.snapshot.url.slice(-1).toString();
    this.service = await this.getSpecificServiceAndMethod(subService);
    if (this.service) {
      this.seoService.setMetaTags(
        this.service.meta_title,
        this.service.meta_description
      );
    }
  }

  private async getSpecificServiceAndMethod(subService: string): Promise<Service> {
    try {
      this.loadingService.setLoading(true);
      const allServices = await this._digitalklangService.getServicesAndMethods();
      const matchingService = allServices.find(x => 
        x.name.replace(/ /g, "-")
          .replace(/&/g, "und")
          .replace(/ü/g, "ue")
          .toLowerCase() === subService
      );

      if (matchingService && matchingService.service_quote && matchingService.service_quote_author) {
        this.quote = {
          author: matchingService.service_quote_author,
          quote: matchingService.service_quote
        };
      }

      const cluster = this.clusterInfoTextsAndTiles(matchingService);
      this.infoTexts = cluster.infoTexts;
      this.tiles = cluster.tileTexts;
      this.loadingService.setLoading(false);
      return matchingService;

    } catch (error) {
      console.error('Error loading service or quote', error);
      this.loadingService.setLoading(false);
      return null;
    }
  }

  clusterInfoTextsAndTiles(service: Service) {
    function groupAttributes(prefix: string) {
      const groupedArray = [];
  
      Object.keys(service).forEach(key => {
        const match = key.match(new RegExp(`^${prefix}_(title|content|image|text)_(\\d+)$`));
        if (match) {
          const [_, type, number] = match;
          let obj = groupedArray.find(item => item.number === number);
  
          if (!obj) {
            obj = { number };
            groupedArray.push(obj);
          }
  
          obj[`${prefix}_${type}`] = service[key];
        }
      });
  
      return groupedArray
        .filter(obj => {
          const keys = Object.keys(obj);
          const hasAllTextContent = keys.every(key => {
            if (key.includes('image')) {
              return obj[key] !== false && obj[key] !== '';
            }
            return obj[key] !== '';
          });
          return hasAllTextContent;
        })
        .map(({ number, ...rest }) => rest);
    }
  
    return {
      tileTexts: groupAttributes('tile'),
      infoTexts: groupAttributes('info')
    };
  }
}
