<div class="about-company">
  <h1>{{ about_texts.about_title }}</h1>
  <div nz-row>
    <div nz-col nzXs="24" nzLg="12">
      <h2 class="about-title">digitalklang creating solutions</h2>
      <div class="about-company__text">
        <div [innerHtml]="about_texts.about_body"></div>
      </div>
    </div>
    <div nz-col class="about-company__image" nzXs="24" nzLg="12">
      <img [src]="about_texts?.about_image?.url" [alt]="about_texts?.about_image?.alt || null">
    </div>
  </div>
  <hr>
  <div nz-row>
    <div nz-col>
      <h2>{{ team_content.about_us_team_title }}</h2>
      <div class="about-company__text__team">
        <div [innerHtml]="team_content.about_us_team_body"></div>
      </div>
    </div>
    <div *ngIf="team_content.about_us_team_image" class="about-company__image-container" nz-col>
      <div class="about-company__work-image">
        <img [src]="team_content.about_us_team_image.url" alt="{{team_content.about_us_team_image['alt']}}">
      </div>
    </div>
  </div>