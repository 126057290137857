import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Headline, DigitalklangService } from 'src/shared/wordpress/digitalklang.service';

@Component({
  selector: 'app-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.less']
})
export class HomeHeaderComponent implements OnInit {

  headlines: Headline[] = [];
  headline: Headline = { title: '' };
  typewriterText: string = '';
  typewriterIndex: number = 0;

  constructor(private _wordpressService: DigitalklangService, @Inject(PLATFORM_ID) private _platformId: Object) { }

  ngOnInit(): Promise<void> {
    return this.loadHeadlines();
  }

  private async loadHeadlines(): Promise<void> {
    this.headlines = await this._wordpressService.getHeadlines();

    if (this.headlines.length > 0) {
      if (isPlatformBrowser(this._platformId)) {
        this._rotateHeadline(0, 5000);
      } else {
        this._showHeadline(0);
      }
    }
  }

  private async _showHeadline(headlineIndex: number): Promise<void> {
    this.headline = this.headlines[headlineIndex];
    this.headline.title = this.headline.title.replace(/&shy;/g, '\u00AD'); // &shy; durch Unicode Soft Hyphen ersetzen
    this._resetTypewriter();
    await this._typeWriterEffect();
  }

  private async _rotateHeadline(headlineIndex: number, displayTimeMs: number) {
    await this._showHeadline(headlineIndex);
    setTimeout(() => this._rotateHeadline((headlineIndex + 1) % this.headlines.length, displayTimeMs), displayTimeMs);
  }


  private _resetTypewriter() {
    this.typewriterText = '';
    this.typewriterIndex = 0;
  }

  private _typeWriterEffect(): Promise<void> {
    return new Promise((resolve) => {
      const typeWriterStep = () => {
        if (this.typewriterIndex < this.headline.title.length) {
          this.typewriterText += this.headline.title.charAt(this.typewriterIndex);
          this.typewriterIndex++;
          setTimeout(typeWriterStep, 100);
        } else {
          resolve();
        }
      };
      typeWriterStep();
    });
  }
}
