<div class="reference" [class]="'reference--' + size">
  <div [nzGutter]="[8, 25]" *ngIf="size === 'large'" class="header">
    <div>
      <h2 class="header__name">{{ reference.name }}</h2>
    </div>
    <div *ngIf="reference.logo && showLogo" class="header__logo">
      <img [src]="reference.logo.url" [alt]="reference.logo.alt">
    </div>
  </div>
  <img class="reference__image" [src]="reference.image.url" [alt]="reference.image['alt']" />
  <div *ngIf="size === 'small'">
    <div class="reference__header">
      <div class="refrence__header__text">
        <h3 class="reference__name">{{ reference.name }}</h3>
        <h4 class="reference__company">für {{ reference.company }}</h4>
      </div>
      <img *ngIf="reference.logo && showLogo" [src]="reference.logo.url" [alt]="reference.logo.alt">
    </div>
    <div class="reference__teaser" [innerHtml]="reference.teaser"></div>
  </div>
  <div class="infos">
    <div *ngIf="reference.services" class="infos__services">
      <h3 *ngIf="size === 'large'">Leistungen<br /></h3>
      <div nz-row [nzGutter]="[16, 16]">
        <div class="service" nz-col *ngFor="let service of reference.services">
          <div class="service__text">{{ service.name }}</div>
        </div>
      </div>
    </div>
    <div *ngIf="size === 'large' && reference.technologies" class="infos__technologies">
      <h3>Eingesetzte Technologien<br /></h3>
      <div class="technologies">
        <div class="technology" *ngFor="let technology of reference.technologies">
          <div class="technology__icon">
            <img [src]="technology.acf.image.url" [alt]="technology.acf.image.alt">
          </div>
          <div class="technology__title">
            {{ technology.acf.name }}
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="size === 'large'"class="reference__teaser" [innerHtml]="reference.teaser"></div>
    <button *ngIf="reference.has_detail" class="infos__link" nz-button nzType="primary" nzSize="large" [routerLink]="'/referenz/' + reference.id" aria-label="Zum Projekt oder zu den Details">
      {{ size === 'small' ? 'Zum Projekt' : 'Details' }}
    </button>
  </div>
  <hr>
</div>
