import { Injectable } from '@angular/core';
import { AppConsts } from '../AppConsts';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { WordpressService } from './wordpress.service';

const WORDPRESS_API_1 = 'action/v1';

export interface Acf<T> {
  acf: T;
}

export interface Image {
  url: string;
}

export interface Taxonomy {
  name: string;
}

export interface Headline {
  title: string;
}

export interface HomePage {
  dna_title: string;
  dna_meta_title:string;
  dna_meta_description:string;
  dna_body: string;
  sam_title: string;
  sam_body: string;
  about_title: string;
  about_body: string;
  about_image: Image;
  career_title: string;
  career_body: string;
}

export interface AboutPage {
  about_us_title: string;
  about_meta_title:string;
  about_us_subtitle: string;
  about_meta_description:string;
  about_us_body: string;
  about_us_image: Image;
  about_us_team_title: string;
  about_us_team_body: string;
  about_us_team_image: Image;
  career_title: string;
  career_body: string;
}

export interface TeamMember {
  team_member_name: string;
  team_member_profession: string;
  team_member_image: string;
}
export interface PrivacyPage {
  title: string;
  body: string;
  privacy_meta_title: string;
  privacy_meta_description: string;
}

export interface ReferencePage {
  references_meta_title: string;
  references_meta_description: string;
}

export interface ImpressumPage {
  title: string;
  body: string;
  impressum_meta_title: string;
  impressum_meta_description: string;
}
export interface Fact {
  value: number;
  unit: string;
  description: string;
}

export interface Management{
  full_name: string,
  occupation: string,
  profession:string,
  image: Image,
  links1: {
    image: string,
    url: string
  },
  links2: {
    image: Image,
    url: string
  },
  links3: {
    image: Image,
    url: string
  }
}

export interface Technology {
  name: string;
  image: Image;
  order: number;
}

export interface Service {
  name: string;
  meta_title: string;
  meta_description: string;
  type: 'service' | 'method';
  order: number;
  short_teaser: string;
  teaser: string;
  reference?: number;
  service_quote: string,
  service_quote_author: string,
  info_title_1: string;
  info_content_1: string;
  info_image_1: Image;
  info_title_2: string;
  info_content_2: string;
  info_image_2: Image;
  info_title_3: string;
  info_content_3: string;
  info_image_3: Image;
  info_title_4: string;
  info_content_4: string;
  info_image_4: Image;

  tile_header_title: string;
  tile_header_content: string;

  tile_text_1: string;
  tile_content_1: string;
  tile_text_2: string;
  tile_content_2: string;
  tile_text_3: string;
  tile_content_3: string;
  tile_text_4: string;
  tile_content_4: string;
  tile_text_5: string;
  tile_content_5: string;
  tile_text_6: string;
  tile_content_6: string;
}

export interface InfoText {
  title: string;
  content: string;
  image: Image;
}

export interface TileText {
  text: string;
  image: Image;
}

export interface Reference {
  name: string;
  reference_meta_title: string;
  reference_meta_description: string;
  image: Image;
  services: Taxonomy[];
  teaser: string;
  featured: boolean;
  technologies: Acf<Technology>[];
  company: string;
  logo: Image;
  id: number;
  has_detail: boolean;
}

export interface Testimonial {
  quote: string;
  origin: string;
  fact1_title: string;
  fact1_content: string;
  fact2_title: string;
  fact2_content: string;
  fact3_title: string;
  fact3_content: string;
  fact4_title: string;
  fact4_content: string;
  image: Image;
  reference?: number;
  visible_on_page: string[];
}

export interface Quote {
  quote: string,
  author: string
}

export interface ReferenceCustomer {
  logo: string;
}

export interface Scrum {
  title: string;
  text: string;
  logo: Image;
}

export interface CareerPage {
  career_meta_title: string;
  career_meta_description: string;
  career_image: Image;
  career_body: Text;
  contact_person_image: Image;
  contact_person_body: Text;
  
}

export interface ReferenceCustomer {
  logo: string;
}

export interface Job {
  title: string;
  short_description: string;
  description: string;
}

export interface CaptchaSettings {
  site_key: string;
}

export interface ContactInformation {
  name: string;
  email: string;
  telephone: string;
  message: string;
  captcha_token: string;
}

@Injectable({
  providedIn: 'root',
})
export class DigitalklangService extends WordpressService {
  constructor(client: HttpClient) {
    super(AppConsts.wordpressBaseUrl, client);
  }

  public getHeadlines(): Promise<Headline[]> {
    return this.getResources<Headline>('headlines');
  }

  public getTechnologies(): Promise<Technology[]> {
    return this.getCustomFields<Technology>('technologies');
  }

  public getHomePage(): Promise<HomePage> {
    return this.getPage<HomePage>('home');
  }

  public getPrivacyPage(): Promise<PrivacyPage> {
    return this.getPage<PrivacyPage>('privacy');
  }

  public getReferencePage(): Promise<ReferencePage> {
    return this.getPage<ReferencePage>('references');
  }

  public getImpressumPage(): Promise<ImpressumPage> {
    return this.getPage<ImpressumPage>('impressum');
  }

  public getCareerPage(): Promise<CareerPage> {
    return this.getPage<CareerPage>('career');
  }
  public getAboutPage(): Promise<AboutPage> {
    return this.getPage<AboutPage>('überUns');
  }

  public getScrumPage(): Promise<Scrum> {
    return this.getPage<Scrum>('scrum');
  }

  public getFactsAndFigures(): Promise<Fact[]> {
    return this.getCustomFields<Fact>('facts', 3);
  }

  public  getManagements(): Promise<Management[]> {
    return this.getCustomFields<Management>('management');
  }
  public getServicesAndMethods(): Promise<Service[]> {
    return this.getCustomFields<Service>('services');
  }

  public getReferences(): Promise<Reference[]> {
    return this.getCustomFields<Reference>('references');
  }

  public getTestimonials(): Promise<Testimonial[]> {
    return this.getCustomFields<Testimonial>('testimonials');
  }

  public getReferencesCustomer(): Promise<ReferenceCustomer[]> {
    return this.getCustomFields<ReferenceCustomer>('customers');
  }

  public getJobs(): Promise<Job[]> {
    return this.getCustomFields<Job>('jobs');
  }

  public getCaptchaSettings(): Promise<CaptchaSettings> {
    return this.getSettings('captcha');
  }

  public validateCaptchaToken(token: string): Promise<boolean> {
    return this.client
      .post<{ success: boolean }>(`${this.baseUrl}/settings/v1/captcha/verify`, { captcha_token: token })
      .toPromise()
      .then((response) => response.success)
      .catch(() => false);
  }

  public async sendContact(data: ContactInformation): Promise<void> {
    await this.client
      .post(`${this.baseUrl}/${WORDPRESS_API_1}/contact`, data)
      .toPromise();
  }

  private async getSettings<T>(setting: string): Promise<T> {
    return this.client
      .get<T>(`${this.baseUrl}/settings/v1/${setting}`)
      .toPromise();
  }

  private async getPage<T>(pageName: string): Promise<T> {
    const pages = await this.pages<any>();
    for (let i = 0; i < pages.length; i++) {
      const page = pages[i];
      if (page.template == `${pageName}.php`) {
        return page.acf as T;
      }
    }
  }

  private async getResources<T>(resource: string): Promise<T[]> {
    const posts = await this.resource(resource);
    return _.map(posts, (post: any) => {
      post.title = post.title?.rendered;
      post.content = post.content?.rendered;
      return post;
    });
  }

  private async getCustomFields<T>(
    resource: string,
    maxCount: number = 100
  ): Promise<T[]> {
    const data = await this.acf<any>(resource, maxCount);
    return _.map(data, (item) => {
      let acf = item.acf;
      acf.id = item.id;
      return acf;
    });
  }
}
