import { Component, OnInit } from '@angular/core';
import { HomePage, DigitalklangService } from 'src/shared/wordpress/digitalklang.service';
import { ServiceWithLink } from '../models/ServiceWithLink';

@Component({
  selector: 'app-home-services',
  templateUrl: './home-services.component.html',
  styleUrls: ['./home-services.component.less']
})
export class HomeServicesComponent implements OnInit {

  services: ServiceWithLink[] = [];
  texts = {} as HomePage;

  constructor(private _wordpressService: DigitalklangService) { }

  async ngOnInit(): Promise<void> {
    await this.loadServicesAndTexts();
    return;
  }

  private async loadServicesAndTexts(): Promise<void> {
    try {
      [this.services, this.texts] = await Promise.all([
        (await (this._wordpressService.getServicesAndMethods())).sort((a, b) => {
          if (a.order !== undefined && b.order !== undefined) {
              if (Number(a.order) <= Number(b.order)) {
                  return -1;
              } else {
                  return 1;
              }
          } else if (a.order !== undefined && b.order === undefined) {
              return -1;
          } else if (a.order === undefined && b.order !== undefined) {
              return 1;
          } else {
              return 0;
          }
      }).map(service => ({ ... service, link: service.name.replace(/ /g, "-").replace(/&/g, "und").replace(/ü/g, "ue").toLowerCase()})),
        this._wordpressService.getHomePage(),
      ]);
    } catch (error) {
      console.error('Error loading services or texts', error);
    }
  }

}
