import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})

export class SeoService {
  constructor(private titleService: Title, private metaService: Meta) {}

  setTitle(title: string): void {
    this.titleService.setTitle(title);
    this.metaService.updateTag({ name: 'title', content: title });
  }

  setDescription(description: string): void {
    this.metaService.updateTag({ name: 'description', content: description });
  }

  setMetaTags(title: string, description: string): void {
    this.setTitle(title);
    this.setDescription(description);
  }
}