<div class="career-headline">
    <h1>Karriere</h1>
    <div nz-row>
        <div nz-col class="career-headline__text" nzXs="24" nzLg="12">
            <p [innerHTML]="texts.career_body"></p>
        </div>
        <div nz-col class="career-headline__image" nzXs="24" nzLg="12">
            <img [src]="texts?.career_image?.url" [alt]="texts?.career_image?.alt || null">
        </div>
    </div>
</div>