import { Component, Input, OnInit } from '@angular/core';
import { DigitalklangService, TileText } from 'src/shared/wordpress/digitalklang.service';
import { TileContent } from '../models/TileInterface';

@Component({
  selector: 'app-tiles',
  templateUrl: './tiles.component.html',
  styleUrls: ['./tiles.component.less']
})
export class TilesComponent implements OnInit {

  @Input() componentTitle: string;
  @Input() componentContent: string;
  @Input() tiles: TileText[];

  constructor(private _wordpressService: DigitalklangService) { }

  async ngOnInit(): Promise<void> {}

  scrollToContactArea() {
    if(typeof document == "undefined"){
      return;
    }
    const contactArea = document.getElementById('contact-area');
    if (contactArea) {
      contactArea.scrollIntoView({ behavior: 'smooth' });
    }
  }

}
