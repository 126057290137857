import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[lazyLoadImage]'
})
export class LazyLoadImageDirective implements OnInit {
  @Input('lazyLoadImage') src: string; // URL des tatsächlichen Bildes
  @Input() placeholder: string; // URL des Platzhalterbildes

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    const imageElement = this.el.nativeElement;
    const options = {
      root: null, // Das Viewport wird als root benutzt
      rootMargin: '0px',
      threshold: 0.01 // 1% Sichtbarkeit
    };

    // Setze zunächst das Platzhalterbild
    this.renderer.setAttribute(imageElement, 'src', this.placeholder);

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        this.loadImage(imageElement, this.src, observer);
      }
    }, options);

    observer.observe(imageElement);
  }

  private loadImage(imageElement: HTMLImageElement, src: string, observer: IntersectionObserver) {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      // Wenn das Bild geladen ist, setze das eigentliche Bild
      this.renderer.setAttribute(imageElement, 'src', src);
    };
    img.onerror = () => {
      console.error(`Image failed to load: ${src}`);
    };

    observer.unobserve(imageElement);
  }
}
