<div class="job">
  <ng-container *ngFor="let job of jobs">
    <nz-collapse [nzExpandIconPosition]="'right'" [nzGhost]="true">
      <nz-collapse-panel [nzHeader]="headerTemplate">
        <div nz-row>
          <div nz-col nzMd="24" nzLg="0">
            <p class="job__short-description">{{job.short_description}}</p>
          </div>
          <div nz-col nzXs="24" class="job__description" [innerHtml]="job.description"></div>
        </div>
      </nz-collapse-panel>
    </nz-collapse>
    <ng-template #headerTemplate>
      <div nz-row>
        <div nz-col nzXs="24" nzLg="12">
          <p class="job__title">{{job.title}}</p>
        </div>
        <div nz-col nzXs="0" nzMd="0" nzLg="12">
          <p class="job__short-description">{{job.short_description}}</p>
        </div>
      </div>
    </ng-template>
  </ng-container>
</div>