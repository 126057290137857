import { Component, OnInit } from '@angular/core';
import { HomePage, DigitalklangService } from 'src/shared/wordpress/digitalklang.service';

@Component({
  selector: 'app-home-career',
  templateUrl: './home-career.component.html',
  styleUrls: ['./home-career.component.less']
})
export class HomeCareerComponent implements OnInit {

  texts = {} as HomePage;

  constructor(private _wordpressService: DigitalklangService) { }

  ngOnInit(): Promise<void> {
    return this.loadHomePageTexts();
  }

  private async loadHomePageTexts(): Promise<void> {
    this.texts = await this._wordpressService.getHomePage();
  }
}
