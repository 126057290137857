import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortBy'
})
export class SortByPipe implements PipeTransform {
  transform<T>(array: T[], order: 'asc' | 'desc', key: keyof T): T[] {
    if (!array || !order || !key) return array;

    const sortedArray = array.sort((a, b) => {
      if (a[key] < b[key]) return order === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return order === 'asc' ? 1 : -1;
      return 0;
    });

    return sortedArray;
  }
}
